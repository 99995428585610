import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import PersonPanel from "../components/globals/PersonPanel";
import MagazineContainer from "../components/project-list/MagazineContainer";
import { ProjectCardList } from "../components/project-list/ProjectCard";
import Book from "../components/globals/Book";

export const query = graphql`
	query ProfileTemplateQuery($id: String!) {
		person: sanityPerson(id: { eq: $id }) {
			image {
				crop {
					_key
					_type
					top
					bottom
					left
					right
				}
				hotspot {
					_key
					_type
					x
					y
					height
					width
				}
				asset {
					_id
				}
			}
			name
			_rawBio
			institution {
				name
				url
			}
			program {
				name
			}
			email
			facebook
			instagram
			linkedIn
			twitter
			vimeo
			keywords {
				value
			}
		}
		projects: allSanityProject(
			filter: { members: { elemMatch: { person: { id: { eq: $id } } } } }
		) {
			nodes {
				title
				_id
				publishedAt
				slug {
					current
				}
				members {
					_key
					person {
						image {
							crop {
								_key
								_type
								top
								bottom
								left
								right
							}
							hotspot {
								_key
								_type
								x
								y
								height
								width
							}
							asset {
								_id
							}
						}
						name
						_rawBio
						_rawResearchSummaryShort
						institution {
							name
							url
						}
						program {
							name
						}
						email
						facebook
						instagram
						linkedIn
						twitter
						vimeo
						keywords {
							value
						}
					}
					roles
				}
				mainImage {
					crop {
						_key
						_type
						top
						bottom
						left
						right
					}

					hotspot {
						_key
						_type
						x
						y
						height
						width
					}
					asset {
						_id
						metadata {
							dimensions {
								aspectRatio
							}
						}
					}
					alt
				}
			}
		}
	}
`;

const ProfileTemplate = (props) => {
	const { data, errors } = props;
	const profile = data && data.person;
	const projects = data && data.projects;
	console.log(data);

	let nodes = projects.nodes;
	const discipline = "all";

	/* Return 2 arrays, first is the project previes second is the project name cards */
	const projectComponents = ProjectCardList({
		nodes: nodes,
		discipline: discipline,
		hideHovers: true
	});
	const projectPreviews = projectComponents[0];
	const projectNames = projectComponents[1];

	return (
		<Layout>
			{errors && <SEO title="GraphQL Error" />}
			{profile && <SEO title={profile.name || "Untitled"} />}

			{errors && (
				<Container>
					<GraphQLErrorList errors={errors} />
				</Container>
			)}

			<Book>
				{profile && <PersonPanel {...profile} />}
				<MagazineContainer
					names={projectNames}
					projects={projectPreviews}
					className="right"
				/>
			</Book>
		</Layout>
	);
};

export default ProfileTemplate;
